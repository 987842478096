import { Component, Inject, NgZone, OnInit, output, Renderer2, signal } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { TelegramService } from '@libs/app.services/account/telegram.service';
import { first, finalize, tap } from 'rxjs';

@Component({
  selector: 'app-telegram-sign-button',

  imports: [CommonModule],
  templateUrl: './telegram-sign-button.component.html',
  styleUrl: './telegram-sign-button.component.scss',
})
export class TelegramSignButtonComponent implements OnInit {
  onlogged = output<boolean>();
  apiUrl = 'https://api.lbets.io/telegram/auth';
  loading = signal(false);
  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private telegramService: TelegramService,
    private ngZone: NgZone,
  ) {}

  /**
   * Initializes the component by creating and appending the Telegram login script.
   * This method sets up the Telegram widget and defines the authentication callback.
   */
  ngOnInit() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://telegram.org/js/telegram-widget.js?7';
    script.async = true;
    script.setAttribute('data-telegram-login', this.telegramService.botName);
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-onauth', 'onTelegramAuth(user)');
    script.setAttribute('data-request-access', 'write');
    window['onTelegramAuth'] = data => this.ngZone.run(() => this.onAuth(data));

    const wrapper = this.document.querySelector('.wrapper-sign-telegram');
    if (wrapper) {
      this.renderer.appendChild(wrapper, script);
    }
  }

  /**
   * Handles the authentication process when a user logs in via Telegram.
   * This method is called by the Telegram widget after successful authentication.
   * It prevents multiple simultaneous authentication attempts and manages the loading state.
   * @param data - The authentication data received from Telegram.
   */
  onAuth(data: any) {
    if (this.loading()) return;
    this.loading.set(true);
    this.telegramService
      .authenticateBy(data)
      .pipe(
        tap(user => this.onlogged.emit(user)),
        finalize(() => this.loading.set(false)),
        first(),
      )
      .subscribe();
  }
}
